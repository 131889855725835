module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-source-wordpress@7.13.4_gatsby-plugin-image@3.13.1_gatsby-plugin-sharp@5.13.1_gatsby-t_6ssdfxjmu4wy75xvch3av2gsmy/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://safesitefacilities.temp513.kinsta.cloud/graphql","production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"perPage":25,"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"previewRequestConcurrency":5},"type":{"MediaItem":{"createFileNodes":true,"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","parent","children"],"localFile":{"excludeByMimeTypes":["video/mp4","image/jpeg","image/png","image/gif","image/svg+xml"],"maxFileSizeBytes":50000000,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@5.13.7_rea_zpf5sokthloqfuumyumkmjgehe/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TMJF7F","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../gatsby-theme-safesite/gatsby-browser.jsx'),
      options: {"plugins":[],"brand":"SafeSite Facilities","wordpressSourceUrl":"https://safesitefacilities.temp513.kinsta.cloud","googleTagManagerId":"GTM-TMJF7F","siteUrl":"https://www.safesitefacilities.co.uk","optimizeId":"OPT-TCZZJ4Q","trustpilotBusinessUnitId":"59ca23da0000ff0005ac124e","trustpilotUrl":"https://uk.trustpilot.com/review/safesitefacilities.co.uk","calltracksClass":"calltracks_safesitefacilities-main","productReviewFormEndpoint":"https://getform.io/f/c0e09f4b-8dbe-42f4-b94c-d41ecc0f91e9","careerApplicationFormEndpoint":"https://getform.io/f/a5c0bca9-0599-4edd-ae2c-12eb474c39be","quoteRequestFormEndpoint":"https://getform.io/f/154d926c-b46c-4910-9f5f-65a5fc420ff2","contactFormEndpoint":"https://getform.io/f/23fcfa2d-f6af-4f99-87f0-dc8b7dd0dcce","feedbackFormEndpoint":"https://getform.io/f/38c54249-991a-4bd3-af90-f74c0dbc8766","quoteBasketFormEndpoint":"https://getform.io/f/9a7ecb1c-1b2d-4208-881d-182710837c70","largeNavFont":true},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SafeSite Facilities","short_name":"SafeSite Facilities","lang":"en","start_url":"/","background_color":"#f7f0eb","theme_color":"#0174af","display":"standalone","icon":"src/images/icon.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"574a98179cb316aeae73fa98b53a2938"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
